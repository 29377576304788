import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Desktop Images
import Section1 from "../../assets/img/main/web/section1.svg";
import Section2 from "../../assets/img/main/web/section2.svg";
import Section3 from "../../assets/img/main/web/section3.svg";

// Mobile Images
import MobileSection1 from "../../assets/img/main/mobile/mobileSection1.png";
import MobileSection2 from "../../assets/img/main/mobile/mobileSection2.png";
import MobileSection3 from "../../assets/img/main/mobile/mobileSection3.png";
import MobileSection4 from "../../assets/img/main/mobile/mobileSection4.png";

import CardCarousel from "./CardCarousel";
import PosterCarousel from "./PosterCarousel";

export default function Main() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // 监听窗口大小变化
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container className="container flexColumn">
            {/* Carousel Section */}
            <CardCarousel />

            {/* First Section */}
            <Wrapper className="flexRow">
                <TitleWrapper className="flexRow">
                    <Title>One-click calendar sharing</Title>
                </TitleWrapper>
                <Img src={isMobile ? MobileSection1 : Section1} alt="Section1" />
            </Wrapper>

            {/* Second Section */}
            <Wrapper className="flexRow">
                <TitleWrapper className="flexRow">
                    <Title>What are your friends up to</Title>
                </TitleWrapper>
                <Img src={isMobile ? MobileSection2 : Section2} alt="Section2" />
            </Wrapper>

            {/* Third Section */}
            <Wrapper className="flexRow">
                {isMobile ? (
                    <TitleWrapper className="flexRow">
                        <Title>What are your friends up to</Title> 
                    </TitleWrapper>
                    
                ) : (
                    <TitleWrapper className="flexRow">
                        <Title>Group chats for plans</Title>
                    </TitleWrapper>
                    
                )}
                
                <Img src={isMobile ? MobileSection3 : Section3} alt="Section3" />
            </Wrapper>

            {/* Fourth Section (仅移动端) */}
            {isMobile && (
                <Wrapper className="flexRow">
                    <TitleWrapper className="flexRow">
                        <Title>Group chats for plans</Title>
                    </TitleWrapper>
                    
                    <Img src={MobileSection4} alt="MobileSection4" />
                </Wrapper>
            )}

            {/* Poster Section */}
            <PosterCarousel />
        </Container>
    );
}

// ========== Styled Components ==========
const Container = styled.section`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    background: none;
    position: relative;
    z-index: 0;
    opacity: 0;
    animation: fadeIn 1s ease-in forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .slick-slide {
        background: none;
    }
`;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 60px 0;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    background: none;
    position: relative;
    opacity: 0;
    animation: slideUp 0.8s ease-out forwards;
    animation-delay: 0.3s;

    @keyframes slideUp {
        from {
            opacity: 0;
            transform: translateY(40px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &:hover {
        transform: translateY(-8px) scale(1.01);
    }

    @media (max-width: 768px) {
        margin: 40px 0;
    }
`;

const Img = styled.img`
    width: 100%;
    max-width: 1200px;
    height: auto;
    object-fit: contain;
    position: relative;
    margin: 40px auto 0;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    filter: brightness(1);
    transform-origin: center;

    &:hover {
        filter: brightness(1.05);
        transform: scale(1.02);
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
    }
`;

const Title = styled.div`
    margin: 0;
    padding: 15px;
    line-height: 1.2;
    font-family: 'Poetsen One';
    font-weight: 400;
    font-size: 56px;
    background: linear-gradient(-53deg, #A515FD 47%, #2763FF 84%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 2px;
        background: linear-gradient(-53deg, #A515FD 47%, #2763FF 84%);
        transition: all 0.4s ease;
        transform: translateX(-50%);
    }

    &:hover {
        transform: scale(1.03);
        
        &:after {
            width: 80%;
        }
    }

    @media (max-width: 768px) {
        font-size: 28px;
        margin-top: 60px;
        padding: 10px;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    opacity: 0;
    animation: fadeIn 0.8s ease-out forwards;
    animation-delay: 0.2s;
    
    @media (max-width: 768px) {
        margin-top: -30px;
        padding: 0 20px;
    }
`;


