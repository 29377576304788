import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import Card1 from "../../assets/img/main/cards/card1.png";
import Card2 from "../../assets/img/main/cards/card2.png";
import Card3 from "../../assets/img/main/cards/card3.png";
import Card4 from "../../assets/img/main/cards/card4.png";
import Card5 from "../../assets/img/main/cards/card5.png";

const cardData = [
    { id: 1, image: Card1, title: "Card 1", description: "Description for Card 1" },
    { id: 2, image: Card2, title: "Card 2", description: "Description for Card 2" },
    { id: 3, image: Card3, title: "Card 3", description: "Description for Card 3" },
    { id: 4, image: Card4, title: "Card 4", description: "Description for Card 4" },
    { id: 5, image: Card5, title: "Card 5", description: "Description for Card 5" },
];

const Container = styled.div`
  padding: 20px;
  outline: none;
  transition: all 0.3s ease-in-out;
  background: transparent;

  &:hover {
    transform: scale(1.02);
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  margin-top: 0;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  background: white;

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
`;

const Title = styled.div`
  width: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 15px;
  line-height: 1.2;
  font-family: 'Poetsen One';
  font-weight: 400;
  font-size: 56px;
  background: linear-gradient(-53deg, #A515FD 47%, #2763FF 84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 80px;
    font-size: 28px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  
  @media (max-width: 768px) {
    margin-top: -50px;
  }
`;

// Add custom styles for the Slick carousel
const SliderWrapper = styled.div`
  .slick-slide {
    padding: 0 10px;
    box-sizing: border-box;
    background: none;
  }

  .slick-list {
    margin: 0 -10px;
    padding: 20px 0;
    background: none;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    background: none;
  }

  .slick-dots {
    bottom: -40px;
    background: none;
  }

  .slick-dots li button:before {
    font-size: 8px;
    color: #A515FD;
    opacity: 0.3;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #A515FD;
  }

  .slick-slide > div {
    background: none;
  }
`;

const CardCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

    return (
        <SliderWrapper className="w-4/5 mx-auto py-6">
            <TitleWrapper>
              <Title>
                  Join friends, anywhere, anytime
              </Title>
            </TitleWrapper>
            <Slider {...settings}>
                {cardData.map((card) => (
                    <Container key={card.id}>
                        <Img src={card.image} alt={card.title} />
                    </Container>
                ))}
            </Slider>
        </SliderWrapper>
    );
};

export default CardCarousel;
