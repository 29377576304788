import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import AppStore from "../../assets/img/header/app-store-download.svg";
import GooglePlay from "../../assets/img/header/google-play-download.svg";

import Instagram from "../../assets/img/footer/Instagram.svg"
import Tiktok from "../../assets/img/footer/Tiktok.svg"
import X from "../../assets/img/footer/X.svg"
import LinkedIn from "../../assets/img/footer/LinkedIn.svg"
import Discord from "../../assets/img/footer/Discord.svg"

import Logo from "../../assets/img/footer/SyncSo.svg";
import QR from '../../assets/img/QRcode/AppStore_QRcode.jpg';
import ModalLogo from "../../assets/img/footer/SyncSo.svg";

export default function Contact() {
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isAppleModalOpen, setIsAppleModalOpen] = useState(false);
  const [isGoogleModalOpen, setIsGoogleModalOpen] = useState(false);      
  
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Container className="container flexColumn">
      {isModalOpen && (
        <ModalOverlay onClick={() => setIsModalOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            
            <ModalImg src={Logo} alt="Logo" />
            <ModalText>Please email us your resume and the position you're looking for. Thanks.</ModalText>
            <EmailLink href="mailto:official@syncso.com"><ModalText>official@syncso.com</ModalText></EmailLink>
            <ModalButton onClick={() => setIsModalOpen(false)}><ModalText>OK</ModalText></ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}

      {isModal2Open && (
        <ModalOverlay onClick={() => setIsModal2Open(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImg src={Logo} alt="Logo" />
            <ModalText>If you have any question, please email</ModalText>
            <EmailLink href="mailto:hello@syncso.com"><ModalText>hello@syncso.com</ModalText></EmailLink>
            <ModalButton onClick={() => setIsModal2Open(false)}><ModalText>OK</ModalText></ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}

      {isAppleModalOpen && (
        <ModalOverlay onClick={() => setIsAppleModalOpen(false)}>
          <ModalContentDownload onClick={(e) => e.stopPropagation()}>
            <ModalImg_Logo src={ModalLogo} alt="Logo" />
            <ModalText>Scan to open App Store</ModalText>
            <ModalImg_QR src={QR} alt="QR" />
            <ModalButton onClick={() => setIsAppleModalOpen(false)}><ModalText>Finish</ModalText></ModalButton>
          </ModalContentDownload>
        </ModalOverlay>
      )}

      {isGoogleModalOpen && (
        <ModalOverlay onClick={() => setIsGoogleModalOpen(false)}>
          <ModalContentDownload onClick={(e) => e.stopPropagation()}>
            <ModalImg_Logo src={ModalLogo} alt="Logo" /> 
            <ModalText>Coming soon..</ModalText>
            <ModalButton onClick={() => setIsGoogleModalOpen(false)}><ModalText>Finish</ModalText></ModalButton>
          </ModalContentDownload>
        </ModalOverlay>
      )}

      <FooterWrapper className="flexRow">
        <FooterLeft className="flexColumn">
          <p className="medium" style={{fontSize: '24px', fontFamily: 'Rubik', marginTop: '20px', marginBottom: '10px'}}>Download</p>
          <DownloadWrapper>
            <Clickable className="pointer">
              {/* <Link to="###" smooth={true} duration={1000}> */}
              {isMobile ? (
                <a href="https://apps.apple.com/us/app/syncso/id6736687327" target="_blank" rel="noopener noreferrer" alt="App">
                  <Img src={AppStore} alt="AppStore"></Img>
                </a>
              ) : (
                <Img src={AppStore} alt="AppStore"
                  onClick={() => setIsAppleModalOpen(true)}
                ></Img>
              )}
              {/* </Link> */}
            </Clickable>
            <Clickable className="pointer">
              {/* <Link to="###" smooth={true} duration={1000}> */}
                <Img
                  src={GooglePlay}
                  alt="GooglePlay"
                  onClick={() => setIsGoogleModalOpen(true)}
                />
              {/* </Link> */}
            </Clickable>
          </DownloadWrapper>

          <div>
            <a href="https://www.instagram.com/syncso?igsh=bDV1c2V0b3VneXgx" target="_blank" rel="noopener noreferrer"><Icon src={Instagram} alt="Instagram"/></a>
            <a href="https://www.tiktok.com/@syncso_?_t=8rrvTlnLwGE&_r=1" target="_blank" rel="noopener noreferrer"><Icon src={Tiktok} alt="Tiktok"/></a>
            <a href="https://x.com/Sync_So_" target="_blank" rel="noopener noreferrer"><Icon src={X} alt="X"/></a>
            <a href="https://www.linkedin.com/company/syncso/" target="_blank" rel="noopener noreferrer"><Icon src={LinkedIn} alt="LinkedIn"/></a>
            <a href="https://discord.gg/s2rSPYp3Gm " target="_blank" rel="noopener noreferrer"><Icon src={Discord} alt="Discord"/></a>
          </div>
        </FooterLeft>

        <FooterRight>
        {isMobile ? (
          <>
          <LinkWrapper>
            <a href="https://mercury-shaker-cc1.notion.site/SYNCSO-TERMS-OF-SERVICE-1871ab55708c80779aefe419de15a3fa?pvs=4" target="_blank" rel="noopener noreferrer"><FooterText>Terms of use</FooterText></a>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"><FooterText>Privacy Policy</FooterText></a>
          </LinkWrapper>
          <LinkWrapper>
            <a href="https://mercury-shaker-cc1.notion.site/SYNCSO-COMMUNITY-GUIDELINES-19c1ab55708c80959106edf18c5a688a?pvs=4" target="_blank" rel="noopener noreferrer"><FooterText>Community Guideline</FooterText></a>
            <a href="###" onClick={() => setIsModalOpen(true)}><FooterText>Join us</FooterText></a>
          </LinkWrapper>
          <LinkWrapper>
            <a href="###" onClick={() => setIsModal2Open(true)}><FooterText>Contact</FooterText></a>
          </LinkWrapper>
          </>
        ) : (
          <>
          <LinkWrapper>
            <a href="https://mercury-shaker-cc1.notion.site/SYNCSO-TERMS-OF-SERVICE-1871ab55708c80779aefe419de15a3fa?pvs=4" target="_blank" rel="noopener noreferrer"><FooterText>Terms of use</FooterText></a>
            <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"><FooterText>Privacy Policy</FooterText></a>
            <a href="https://mercury-shaker-cc1.notion.site/SYNCSO-COMMUNITY-GUIDELINES-19c1ab55708c80959106edf18c5a688a?pvs=4" target="_blank" rel="noopener noreferrer"><FooterText>Community Guideline</FooterText></a>
          </LinkWrapper>
          <LinkWrapper>
            <a href="###" onClick={() => setIsModalOpen(true)}><FooterText>Join us</FooterText></a>
            <a href="###" onClick={() => setIsModal2Open(true)}><FooterText>Contact</FooterText></a>
          </LinkWrapper>
          </>
        )}
          <LinkWrapperPlaceholder></LinkWrapperPlaceholder>
        </FooterRight>
      </FooterWrapper>

      <CopyRightWrapper className="flexCenter">
              <CopyRight className="font16" style={{color: '#475467'}}>
                © {getCurrentYear()} SyncSo All Right Reserved.
              </CopyRight>
      </CopyRightWrapper>
    </Container>
  );
}

const Container = styled.section`
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 50px 50px 10px 50px;

  @media (max-width: 1024px) {
    // width: 70%;
    width: auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FooterLeft = styled.div`
  width: 40%;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const FooterRight = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const LinkWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1248px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
const LinkWrapperPlaceholder = styled.div`
  width: 30%;
  flex-direction: column;
  @media (max-width: 1248px) {
    display: none;
  }
`;

const FooterText = styled.div`
  color: #475467;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  @media (max-width: 1248px) {
    width: 190px;
    text-align: center;
    font-size: 14px;
  }
`;

const CopyRightWrapper = styled.div`
  padding: 30px;
  margin: 0px 50px; 
  border-top-width: 2px;
  border-top-color: #F1F7FC;
  border-style: solid;
`;

const CopyRight = styled.p`
  font-size: 14px;
  @media (max-width: 550px) {
    margin: 20px 0;
    font-size: 12px;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  margin: 20px 0px 30px 0px;
  gap: 20px;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Clickable = styled.div`
  width: 152px;
  height: 48px;
  margin: 10px 30px 10px 0px;
  position: relative;
  z-index: 10;
  @media (max-width: 1248px) {
    margin: 10px 15px 10px 15px;
  }
`;

const Img = styled.img`
  width: 160px;
  height: auto;
`;

const Icon = styled.img`
  width: 32px;
  height: auto;
  margin-right: 25px;
  position: relative;
  z-index: 10;
  @media (max-width: 1248px) {
    margin: 8px 15px 8px 15px;
    width: 28px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  max-width: 400px;
`;

const ModalContentDownload = styled.div`
background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  // max-width: 400px;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1248px) {
    width: 60%;
  }
`;

const EmailLink = styled.a`
  display: block;
  margin: 10px 0;
  color: #175CD3;
  font-weight: bold;
  text-decoration: none;
`;

const ModalButton = styled.button`
  margin-top: 15px;
  padding: 10px 20px;
  background: #175CD3;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  // &:hover {
  //   background: #357ABD;
  // }
`;

const ModalImg = styled.img`
  width: 30%;
  height: auto;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  font-family: 'Rubik', sans-serif;
`;

const ModalImg_Logo = styled.img`
  width: 40%;
  height: auto;
  margin-bottom: 20px;
  
`;

const ModalImg_QR = styled.img`
  width: 60%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 20px;
`;