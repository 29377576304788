import React, { useEffect, useState } from 'react';
import '../style/EventDetail.css';
import CustomCarousel from '../components/Event/ImageSlider';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const EventDetail = () => {
  const param = useParams();
  const eventId = param.id;
  const [event, setEvent] = useState(null);
  const [comments, setComments] = useState(null);
  const [downLoadModal, setDownLoadModal] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const fetchEvent = async () => {
    try {
      const response = await fetch(`https://syncso.com/api/v1/public/event/${eventId}`)
      const data = await response.json();
      setEvent(data);
    } catch (error) {
      console.error("err", error);
    }
  }

  const fetchComments = async () => {
    try {
      const response = await fetch(`https://syncso.com/api/v1/public/comments/list/${eventId}`)
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error("err", error);
    }
  }

  useEffect(() => {
    fetchEvent();
    fetchComments();
  }, [eventId]);

  const formatCommentTime = (createdOn) => {
    const now = moment();
    const commentTime = moment(createdOn);
    const diffInSeconds = now.diff(commentTime, 'seconds');
    const diffInMinutes = now.diff(commentTime, 'minutes');
    const diffInHours = now.diff(commentTime, 'hours');
    const diffInDays = now.diff(commentTime, 'days');

    if (diffInSeconds < 60) return 'just now';
    if (now.isSame(commentTime, 'day')) {
      if (diffInMinutes < 60) return `${diffInMinutes}m`;
      return `${diffInHours}h`;
    }
    if (now.clone().subtract(1, 'day').isSame(commentTime, 'day')) {
      return 'Yesterday';
    }
    if (diffInDays < 7) return `${diffInDays}d`;
    return commentTime.format('MM-DD');
  };

  const dateToMonthDayString = (date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
  }

  const dateToTimeZoneString = (date, endTime) => {
    let option = {
      hour: 'numeric',
      minute: 'numeric',
    }
    if (endTime) {
      option.timeZoneName = 'short'
    }
    return new Intl.DateTimeFormat('en-US', option).format(date)
  }

  const formatLocation = (event, userId) => {
    if (event?.location) {
      if (event.creatorId === userId || event?.eventRequestStatus === 'accepted') {
        const shorterLocation = event.location.address.split(', ').slice(-3).slice(0, 2).join(', ')
        return shorterLocation
      } else {
        if (event.location.latitude === null && event.location.longitude === null) {
          return event.location.address
        }
        return event.location.locality
      }
    }
    return ''
  }

  const startDate = event?.startDate
    ? dateToMonthDayString(new Date(event.startDate))
    : ''

  const startTime = event?.startTime
    ? dateToTimeZoneString(
      new Date(event.startTime),
      event.endTime === null
    )
    : ''

  const endTime = event?.endTime
    ? dateToTimeZoneString(new Date(event.endTime), event?.endTime)
    : ''

  const formatTime = endTime ? startTime + "-" + endTime : startTime
  const firstLineTime = (startDate && startTime) ? startDate + ", " + formatTime : startDate ? startDate : formatTime;

  const maxAttendants = event?.maxAttendants
    ? `${event.maxAttendants} Max`
    : null
  const joinedNum = `${event?.attendants?.length || 1} Joined`
  const maxAndJoined = [joinedNum, maxAttendants].filter(Boolean).join(', ')

  const isLocationOnline = event?.location?.address === 'online'

  const closeModal = () => {
    setDownLoadModal(false);
  }

  const openModal = () => {
    setDownLoadModal(true);
  }

  const fetchTurboLink = async () => {
    try {
      const reqBody = {
        "project_id": "ct6os1n5ar9ja3ohgc80",
        "title": "syncso sharing link",
        "link_data": [
          {
            "key": "eventId",
            "value": `${eventId}`
          }
        ],
        "redirections": {
          "redirect_default": "https://syncso.com",
          "redirect_ios": "https://apps.apple.com/us/app/syncso/id6736687327"
        }      
      }
      const response = await fetch(
        'https://www.allapp.link/v2/deeplink/create',
        {
          method: 'POST',
          headers: {
            'Authorization' : `Bearer ${'4e7d3d03b8083eb54cfc788185d7166d'}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(reqBody),
        }
      )
      const data = await response.json();
      if (data.code === 200) {
        window.open(data.data.link, '_blank');
      }
    } catch (error) {
      console.error("err", error);
    }
  }

  const requestTurboLink = () => {
    fetchTurboLink();
  }

  return (
    <div className="event-post">
      <div className="event-post__image-section">
        <CustomCarousel autoPlay={false}>
          {(event?.userPhotos || []).map((img, index) => (
            <img key={index} src={img} alt={`Event ${index + 1}`} className="carousel__image" />
          ))}
        </CustomCarousel>
      </div>

      <div className="event-post__details-section">
        <h2 className="event-post__title">
          {event?.title || "Event Title"}
        </h2>

        <div className="event-post__author">
          <img 
            src={event?.userResponse?.photoUrl || "https://picsum.photos/200"} 
            alt={event?.userResponse?.displayName || "Unknown User"} 
            className="event-post__author-avatar" 
          />
          <div className="event-post__author-info">
            <div className="event-post__author-name">
              {event?.userResponse?.displayName || "Unknown User"}
            </div>
            <div className="event-post__author-followers">
              {event?.userResponse?.followersCount || 0} {event?.userResponse?.followersCount === 1 ? "Follower" : "Followers"}
            </div>
          </div>
        </div>

        <p className="event-post__description">
          {event?.description || "Event Description"}
        </p>

        {event?.tags && event.tags.length > 0 && (
          <p className="event-post__tags">
            {event.tags.map(tag => `#${tag}`).join(' ')}
          </p>
        )}

        <div className="event-post__info">
          {(event?.startDate || event?.startTime) && (
            <p>📅 {firstLineTime}</p>
          )}
          {event?.location?.address && (
            <p>📍 {isLocationOnline ? 'Online' : formatLocation(event, "")}</p>
          )}
          <p>👥 {maxAndJoined}</p>
        </div>

        <button className="event-post__join-button" onClick={openModal}>
          Join
        </button>

        <div className="event-post__comments">
          <div className="comment_input">
            <img 
              src="https://picsum.photos/200" 
              alt="User" 
              className="comment_input_avatar" 
            />
            <div className="comment_input_bar">
              <input 
                type="text" 
                placeholder="Write a comment..." 
                className="comment_input_text" 
                onClick={openModal}
              />
              <button className="comment_input_button" onClick={openModal}>
                ↑
              </button>
            </div>
          </div>

          <div className="comment-list">
            {comments && comments.Comments?.map((comment, index) => (
              <div key={comment.id + index} className="comment-container">
                <img 
                  src={comment.userProfile?.photoUrl || "https://picsum.photos/200"} 
                  alt={comment.userProfile?.displayName || "Unknown User"} 
                  className="comment_input_avatar" 
                  onClick={openModal}
                />
                <div className="comment-content">
                  <div className="username">
                    {comment.userProfile?.displayName || "Unknown User"}
                  </div>
                  <p className="comment-text">{comment.comment}</p>
                  <div className="comment-time">
                    {formatCommentTime(comment.createdOn)}
                  </div>
                  <div className="comment-actions">
                    <div className="comment-like" onClick={openModal}>
                      {comment.likedByCurrentUser ? "❤️" : "🤍"}
                      {comment.likes > 0 && (
                        <span className="like-count">{comment.likes}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {downLoadModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>SyncSo</h2>
            <p>Please download the mobile app to continue</p>
            <div className="download-buttons">
              <div className="btn" onClick={requestTurboLink}>
                View in App
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetail;
