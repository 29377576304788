import React, { useState, useEffect } from "react";

import { Link, animateScroll as Scroll } from 'react-scroll';
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import SyncoIcon from "../../assets/img/header/Syn-logo-white.svg";
import AppStore from "../../assets/img/header/app-store-download.svg";
import GooglePlay from "../../assets/img/header/google-play-download.svg";
import HeaderBG from '../../assets/img/header/header-bg-img.svg';

import QR from '../../assets/img/QRcode/AppStore_QRcode.jpg';
import ModalLogo from "../../assets/img/footer/SyncSo.svg";

export default function Header() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isAppleModalOpen, setIsAppleModalOpen] = useState(false);
  const [isGoogleModalOpen, setIsGoogleModalOpen] = useState(false);
  
      // 监听窗口大小变化
      useEffect(() => {
          const handleResize = () => {
              setIsMobile(window.innerWidth <= 768);
          };
  
          window.addEventListener("resize", handleResize);
          return () => window.removeEventListener("resize", handleResize);
      }, []);

  return (
    <Wrapper id="home" className="container">
      {isAppleModalOpen && (
        <ModalOverlay onClick={() => setIsAppleModalOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImg_Logo src={ModalLogo} alt="Logo" />
            <ModalText>Scan to open App Store</ModalText>
            <ModalImg_QR src={QR} alt="QR" />
            <ModalButton onClick={() => setIsAppleModalOpen(false)}><ModalText>Finish</ModalText></ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
      {isGoogleModalOpen && (
        <ModalOverlay onClick={() => setIsGoogleModalOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalImg_Logo src={ModalLogo} alt="Logo" /> 
            <ModalText>Coming soon..</ModalText>
            <ModalButton onClick={() => setIsGoogleModalOpen(false)}><ModalText>Finish</ModalText></ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}

      <img src={SyncoIcon} alt="SyncoIcon" style={{position: 'absolute', top:20, left: 40, zIndex: 9}} />
      <LeftSide className="flexColumn flexCenter">
          {/* <HeaderP className="regular font70"> */}
          <HeaderP className="regular">
            Share plans<br />Join friends
          </HeaderP>
          <DownloadWrapper>
            <Clickable className="pointer">
              {/* <Link to="" smooth={true} duration={1000}> */}
              {isMobile ? (
                <a href="https://apps.apple.com/us/app/syncso/id6736687327" target="_blank" rel="noopener noreferrer">
                  <img src={AppStore} alt="AppStore" />
                </a>
              ) : (
                <img src={AppStore}
                  alt="AppStore"
                  onClick={() => setIsAppleModalOpen(true)}
                />
              )}
              {/* </Link> */}
            </Clickable>
            <Clickable className="pointer">
              <Link to="###" smooth={true} duration={1000}>
                <img src={GooglePlay} alt="GooglePlay" 
                  onClick={() => setIsGoogleModalOpen(true)}
                />
              </Link>
            </Clickable>
          </DownloadWrapper>
      </LeftSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  // background img
  background-image: url(${HeaderBG});
  background-size: cover;
  background-position: center;
  position: relative;
  
  @media (max-width: 2424px) {
    flex-direction: column;
    justify-content: center;
    width: 100vw;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 20px;
  @media (max-width: 2424px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
    padding: 0;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const HeaderP = styled.div`
  // Layout
  max-width: 600px;
  padding: 15px 0 50px 0;
  line-height: 84px;
  // Text
  font-family: 'Poetsen One';
  font-size: 70px;
  color: rgba(252, 252, 253, 1);
  margin: 0 auto;

  @media (max-width: 2424px) {
    text-align: center;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 1.2;
    padding: 15px 20px 50px 20px;
  }
`;
const DownloadWrapper = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;
const Clickable = styled.div`
  width: 152px;
  height: 48px;
  z-index: 9;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 2424px) {
    margin: 0;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  width: 100%;
  padding: 0 15%;
  img {
    width: 70%;
    height: auto;
  }
  @media (max-width: 400px) {
    // width: 100%;
    justify-content: center;
    padding: 0;
    margin-bottom: 30px;
  }
`;

const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 30px;
  border-radius: 20%;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  // max-width: 400px;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1248px) {
    width: 60%;
  }
`;

const EmailLink = styled.a`
  display: block;
  margin: 10px 0;
  color: #175CD3;
  font-weight: bold;
  text-decoration: none;
`;

const ModalButton = styled.button`
  margin-top: 15px;
  padding: 10px 20px;
  background: #175CD3;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  // &:hover {
  //   background: #357ABD;
  // }
`;

const ModalImg_Logo = styled.img`
  width: 40%;
  height: auto;
  margin-bottom: 20px;
`;

const ModalImg_QR = styled.img`
  width: 60%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  font-family: 'Rubik', sans-serif;
`;