import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import Poster1 from "../../assets/img/main/posters/poster1.png";
import Poster2 from "../../assets/img/main/posters/poster2.png";
import Poster3 from "../../assets/img/main/posters/poster3.png";
import Poster4 from "../../assets/img/main/posters/poster4.png";
import Poster5 from "../../assets/img/main/posters/poster5.png";

const posterData = [
    { id: 1, image: Poster1 },
    { id: 2, image: Poster2 },
    { id: 3, image: Poster3 },
    { id: 4, image: Poster4 },
    { id: 5, image: Poster5 },
];

const Container = styled.div`
  padding: 20px;
  outline: none;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: none;
  display: flex;
  justify-content: center;
  transform-origin: center;
  filter: brightness(1);

  &:hover {
    transform: scale(1.03) translateY(-5px);
    filter: brightness(1.05);
  }
`;

const Img = styled.img`
  width: 75%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  display: block;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: none;
  transform: perspective(1000px) rotateY(0deg);

  &:hover {
    box-shadow: 0 15px 30px rgba(165, 21, 253, 0.15);
    transform: perspective(1000px) rotateY(5deg);
  }

  @media (max-width: 768px) {
    width: 80%;
    max-width: 260px;
    margin: 0 auto;
  }
`;

const Title = styled.div`
  width: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 15px;
  line-height: 1.2;
  font-family: 'Poetsen One';
  font-weight: 400;
  font-size: 56px;
  background: linear-gradient(-53deg, #A515FD 47%, #2763FF 84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  opacity: 0;
  animation: titleFadeIn 0.8s ease-out forwards;

  @keyframes titleFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: linear-gradient(-53deg, #A515FD 47%, #2763FF 84%);
    transition: all 0.4s ease;
    transform: translateX(-50%);
  }

  &:hover {
    transform: scale(1.03);
    
    &:after {
      width: 80%;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-top: 80px;
    font-size: 28px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  
  @media (max-width: 768px) {
    margin-top: -50px;
  }
`;

// Add custom styles for the Slick carousel
const SliderWrapper = styled.div`
  .slick-slide {
    padding: 0 5px;
    box-sizing: border-box;
    background: none;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .slick-list {
    margin: 0 -5px;
    padding: 20px 0;
    background: none;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
    background: none;
  }

  .slick-dots {
    bottom: -40px;
    background: none;
  }

  .slick-dots li button:before {
    font-size: 8px;
    color: #A515FD;
    opacity: 0.3;
    transition: all 0.3s ease;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #A515FD;
    transform: scale(1.2);
  }

  .slick-slide > div {
    background: none;
  }
`;

const PosterCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SliderWrapper className="w-4/5 mx-auto py-6">
        <TitleWrapper>
          <Title>
              Generate poster for plans
          </Title>
        </TitleWrapper>
        <Slider {...settings}>
            {posterData.map((card) => (
            <Container key={card.id}>
                <Img src={card.image} alt={card.title} />
            </Container>
            ))}
        </Slider>
    </SliderWrapper>
  );
};

export default PosterCarousel;
