import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../components/Sections/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <Wrapper>
        <ContentContainer>
          <Title>SYNCSO PRIVACY POLICY</Title>
          <LastUpdated>Last Updated: Jan, 2025</LastUpdated>

          <Content>
            <div style={{ textAlign: "left", marginBottom: "40px" }}>
              <h2>1. What Is This Privacy Policy?</h2>

              <p>This Privacy Policy applies to the website at <a href="http://www.syncso.com/">http://www.syncso.com/</a>(the "Site") and through our mobile applications and related technologies ("Mobile Apps", and collectively, such Mobile Apps and the Site, including any updated or new features, functionality and technology, the "Service") which is owned, provided, and operated by SyncSo, Inc., a Delaware corporation ("we," "our," and "us"). </p>

              <p>This Privacy Policy applies to the website at  (the "Site") and through our mobile applications and related technologies ("Mobile Apps", and collectively, such Mobile Apps and the Site, including any updated or new features, functionality and technology, the "Service") which is owned, provided, and operated by SyncSo, Inc., a Delaware corporation ("we," "our," and "us").</p>
              <p>Your privacy is important to us. This Privacy Policy describes how we collect, use, share, and process your information, as well as your associated rights. It applies to information we collect on this Service. It does not apply to information collected offline, through any other means, or by any third party, including through any other websites, applications, services, or content (including advertising) that may link to or from this Service.</p>
              <p>We encourage you to read this Privacy Policy and our Terms of Service carefully. If you do not agree with this Privacy Policy, please do not access or use the Service or interact with any other aspect of our business. Capitalized terms used but not defined in this Privacy Policy have the meaning given them in the Terms of Use.</p>
              <br />
              <h2>2. What Personal Information Do We Collect?</h2>
              <p>For the purpose of this Privacy Policy, "personal information" means all information that identifies,
              relates to, describes, can be associated with, or could reasonably identify you as an individual.</p>
              <p>We collect and receive personal information when you register for an SyncSo account or other content access; email, text or otherwise share content via our Service; contact us with a message; post, upload, or otherwise submit to our Services a comment, photo, or other content or user-generated material; register for one of our events or contests; apply for a job, internship or fellowship with us; participate in a survey; or conduct searches via our Service.</p>
              <p>Your personal information can be provided directly by you, collected automatically or inferred by us. The following are the types of personal information that we may collect:</p>
              <ul>
                <li>Name(s)</li>
                <li>Alias</li>
                <li>Online identifier (e.g. social media handles)</li>
                <li>Postal address</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Phone contacts</li>
                <li>Geolocation data</li>
                <li>Gender</li>
                <li>Racial/ethnic/color data</li>
                <li>Audio or visual recordings</li>
                <li>Apple Calendar</li>
                <li>Google Calendar</li>
              </ul>
              <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
              <br />
              <h3>Personal information We Automatically Collect from You</h3>
              <p>We also automatically collect certain technical data that is sent to us from the computer, mobile device and/or browser through which you access the Service.</p>
              <br />
              <h4>Device Data</h4>
              <p>When you visit or use the Service, we may collect identifiers and internet activity information such as IP address, device information, browser type, plug-ins, integrations, internet service provider, mobile carrier, the pages and files viewed, referring website, operating system, system configuration information, and date and time stamps associated with your usage.</p>
              <br />
              <h4>Usage Data</h4>
              <p>We collect information about your usage and activity on the Service, which may include the content of the Service that you visit, the time and date of your visit, the time spent on those pages, views and interactions with content, frequency of your interactions on the Service, and other diagnostic data. In addition, we may use aggregated usage data for other internal business purposes, such as to identify additional customer opportunities and to ensure that we are meeting the demands of our customers and their users.</p>
              <br />
              <h4>Location Data</h4>
              <p>We collect information about your approximate location, including location information based on your IP address.</p>
              <br />
              <h4>Cookies, Web Beacons, and Other Tracking Technologies</h4>
              <p>We use cookies and other tracking technologies to provide certain functionality and features, to recognize you across different services and devices, and to enhance your experience by measuring and analyzing how you use the Service. The technologies we use may include:</p>
              <ul>
                <li><b>Cookies.</b> Cookies are small bits of data stored by a website on your computer or mobile device's hard drive to remember details about your visit. We employ session cookies, which expire when you close your web browser, to help us gather additional information and improve your experience with the Service. If you prefer not to have cookies stored on your hard drive, you may have the option to disable this feature on your computer or mobile device. Refer to your Internet browser's documentation for guidance on how to do this and how to delete persistent cookies. However, if you choose to reject cookies from us, our services may not work as intended.</li>
                <li><b>Web Beacons.</b> The Service may contain web beacons, small graphic images, or other web programming code that permit us, for example, to count users who have visited our Service and for other related website statistics.</li>
              </ul>
              <br />
              <h3>Personal Information We Inferred</h3>
              <p>Consumer Profile Data. We analyze personal information that you provide and we automatically collect to draw inferences and create consumer profiles, which reflect a consumer's preferences, economics, characteristics, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
              <br />
              <h2>3. Why Do We Collect Your Personal information?</h2>
              <p>We collect and process your personal information for the following purposes and rely on the following legal bases.</p>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Purpose</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Description</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Legal Basis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To register and manage user account.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process your personal information to register and manage your account with us, such as service features, customer correspondence, customer relationship management.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Necessary for the performance of a contract or our legitimate interest in the management of user accounts.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To provide and improve the Service.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process your personal information to make sure the Service is working properly and provide you with appropriate content. We also process your personal information to analyze overall trends and help us improve the user experience.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Necessary for the performance of a contract or our legitimate interest to maintain and improve our services.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To provide customer support.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process your personal information to resolve technical issues you encounter, respond to your requests for assistance, analyze crash information, and repair and improve the services.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Necessary for the performance of a contract or our legitimate interest in fulfilling your requests and communicating with you.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To market and promote our services.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We may send promotional communications that may be of interest to you, including by email and by displaying ads. We may also communicate with you about new services, special offers, promotions, and contests.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Our legitimate interest in advertising our services or conducting direct marketing or where you have provided your prior consent.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To conduct business planning, reporting, and forecasting.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process your personal information for analytics and measurement to understand how the Service is used.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Our legitimate interest for internal business purposes.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To maintain safety and security.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process personal information to help improve the safety and reliability of the Services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm the Service, our users, or the public.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Our legitimate interest in promoting the safety and security of the Services generally and to protect our rights and the rights of others.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To comply with legal obligations.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process your personal information when cooperating with public and government authorities, courts, or regulators in accordance with our legal obligations under applicable laws to the extent this requires the processing or disclosure of personal information to protect our rights.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Legal obligation or our legitimate interest in protecting against misuse or abuse of the Services and our services, protecting personal property or safety, pursuing remedies available to us and limiting our damages, complying with judicial proceedings, court orders or legal processes, responding to lawful requests, or for auditing purposes.</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>To integrate with Apple Calendar and Google Calendar</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>We process your calendar data so that you can choose to share your schedule with your friends at your will, enabling better coordination and event planning.</td>
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>Necessary for the performance of a contract or with your explicit consent.</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <h2>4. How Do We Share Your Personal information?</h2>
              <p>We are committed to maintaining your trust. We want you to understand when and with whom we may share your personal information for business purposes.</p>
              <br />
              <h3>Service Providers</h3>
              <p>We may share personal information with our service providers who perform services on our behalf. Examples of our service providers include analytics providers, data storage providers, web hosting vendors, cloud service providers, and payment processing providers. They are contractually required to treat your personal information as confidential and secure and to use it only to provide the services we requested.</p>
              <br />
              <h3>For Legal Reasons</h3>
              <p>Where required by law or where we believe it is necessary to protect our legal rights, interests, and the interests of others, we use information about you in connection with legal claims, compliance, and regulatory. We may also disclose your information, including information about your interaction with the Service, (a) to respond to subpoenas, court orders, legal process, law enforcement requests, legal claims, or government inquiries, (b) to protect and defend the rights, interests, safety, and security of the Service, its users, or the public, and (c) to enforce any terms applicable to the Service.</p>
              <br />
              <h3>Business Transfer</h3>
              <p>We reserve the right to disclose and transfer all information related to the Service, including, without limitation, your personal information and usage information: (i) to a subsequent owner, co-owner or operator of the Service or applicable database; or (ii) in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our stock and/or assets or other corporate change, including, without limitation, during the course of any due diligence process.</p>
              <br />
              <h3>With Your Consent</h3>
              <p>We may share your information for other purposes pursuant to your consent or at your direction. For example, we may publish testimonials or featured customer stories to promote the Service, with your permission.</p>
              <br />
              <h2>5. How Do We Transfer Your Personal information?</h2>
              <p>Your personal information is processed at our operating offices and in any other places where the parties involved in the processing are located. It means that this personal information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
              <p>Your consent to this Privacy Policy followed by your submission of your personal information represents your agreement to that transfer.</p>
              <p>We will take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this Privacy Policy and no transfer of your personal information will take place to an organization or a country unless there are adequate controls in place including the security of your personal information.</p>
              <br />
              <h2>6. How Do We Protect Your Data Security?</h2>
              <p>We take appropriate precautions including organizational, technical, and physical measures to help safeguard against accidental or unlawful destruction, loss, alteration, and unauthorized disclosure of, or access to, the personal data we process or use.</p>
              <p>Despite these efforts, no security measures are perfect, and no method of data transmission or storage is guaranteed to prevent unauthorized disclosure or misuse. As a result, we cannot ensure or warrant the security of any personal data you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unauthorized access, use, disclosure, or loss of Personal Information.</p>
              <br />
              <h2>7. How Long Do We Store Your Personal information?</h2>
              <p>The data we process in providing the Service is non-sensitive personal information. We keep the data for as long as you use the Service or as required to fulfill the purposes for which it was collected, provide the Service, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce agreements, and comply with laws. We determine the appropriate retention period for personal data based on the amount, nature, and sensitivity of the personal data being processed, the potential risk of harm from unauthorized use or disclosure of the personal data, whether we can achieve the purposes of the processing through other means, and on the basis of applicable legal requirements (such as applicable statutes of limitation). We delete personal information when (a) it is no longer used for any purposes, or (b) a data subject requests deletion of their personal information or objects to us processing their data pursuant to their legal rights (unless we have a valid legal justification to retain it, such as to resolve disputes or comply with our legal obligations).</p>
              <br />
              <h2>8. What Are Your Rights Regarding Your Personal information?</h2>
              <p>Depending on the applicable privacy and data protection laws that apply to your situation, you may be able to exercise the following rights regarding your personal information processing by us:</p>
              <br />
              <h3>Right to access</h3>
              <p>You have the right to require: the confirmation on whether we process your personal information or not; a copy of such personal information; and information on the use of your personal information in a clear, transparent, and understandable way (as provided in this Privacy Policy).</p>
              <br />
             <h3>Right to rectification</h3>
              <p>You have the right to require the rectification of your personal information that may be obsolete, inaccurate or incomplete.</p>
              <br />
              <h3>Right to erasure</h3>
              <p>You have the right to require the erasure of your personal information, when one of the following conditions applies:</p>
              <ul>
                <li>Your personal information is no longer necessary for the purposes for which we collected it or otherwise processed;</li>
                <li>You retract your consent on which the processing of your personal information is based, and we have no other legal basis for the processing;</li>
                <li>You object to the personal information processing and we have no compelling legitimate grounds for processing your personal information;</li>
                <li>The use that is made of your personal information does not comply with the applicable legal or regulatory provisions.</li>
              </ul>
              <br />
              <h3>Right to restriction of processing</h3>
              <p>You have the right to require the restriction of the personal information processing during a limited period of time, in particular in order to carry out some verifications, if one of the following conditions applies:</p>
              <ul>
                <li>You contest the accuracy of your personal information, and wish to restrict the processing of your personal information during the period required of us to verify the accuracy of your personal information;</li>
                <li>The personal information processing is unlawful and you oppose the erasure of your personal information and request the restriction of their use instead;</li>
                <li>We no longer need your personal information for the purposes of the processing, but you require them for the establishment, exercise, or defense of legal claims;</li>
                <li>You have objected to the processing of your personal information, and wish to restrict their processing pending the verification of whether our compelling legitimate grounds for processing override your grounds for objecting to it.</li>
              </ul>
              <br />
              <h3>Right to data portability</h3>
              <p>You have the right to require us to provide you with your personal information that you have provided to us, in a structured, commonly used and machine-readable format. You also have the right to require us to transmit this data to another data controller. This right is not exercised in all circumstances, it applies only if it fulfils all the following conditions:</p>
              <ul>
                <li>Your request is only related to your personal information (excluding anonymous or third-party data);</li>
                <li>Your request does not adversely affect the rights or freedoms of others, in particular those of us (including trade secrets);</li>
                <li>The processing is carried out by automated means (paper files are therefore not included);</li>
                <li>The processing is based on consent or the performance of a contract.</li>
              </ul>
              <br />
              <h3>Right to object</h3>
              <p>You have the right to object to our processing of your personal information at any time if:</p>
              <ul>
                <li>The processing is based on our legitimate interest; or</li>
                <li>Your personal information are processed for direct marketing purposes.</li>
              </ul>
              <p>We will no longer process your personal information unless we demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, such as the respect of a legal obligation (e.g. legal obligation involving the retention of personal information), or for the establishment, exercise or defense of legal claims.</p>
              <br />
              <h3>Right to withdraw your consent</h3>
              <p>You have the right to withdraw your consent when it has been obtained, without this withdrawal affecting the lawfulness of the personal information processing operations previously carried out.</p>
              <p>You have control regarding our use of personal information for directing marketing. If you no longer wish to receive any marketing communications, remain on a mailing list to which you previously subscribed, or receive any other marketing communication, you can choose to not receive such communications at any time. Please follow the unsubscribe link in the relevant communication or contact us.</p>
              <p>Depending on your country of residence, you may have additional local rights with respect to our processing of personal information. Please note that some of the personal information that we collect, use and share may be exempt from the rights outlined above.</p>
              <p>To exercise your rights, please contact us by using the information in Section 12 below. Your personal information may be processed in accordance with these rights. We try to respond to all legitimate requests within one month unless otherwise required by law, and will contact you if we need additional information from you in order to honor your request or verify your identity. Occasionally it may take us longer than a month, taking into account the complexity and number of requests we receive.</p>
              <br />
              <h2>9. Controls for Do-Not-Track Features</h2>
              <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
              <br />
              <h2>10. Children Under 18</h2>
              <p>The Service is not intended for use by children under the age of eighteen years old. We do not knowingly collect information from children under the age of eighteen.</p>
              <br />
              <h2>11. PrivacyPolicyUpdates</h2>
              <p>This Privacy Policy may be updated periodically to reflect changes in our privacy practices.</p>
              <p>This Privacy Policy may be updated periodically to reflect changes in our Personal Information practices. We will notify you of any significant changes to our Privacy Policy and indicate at the top of the notice when it was most recently updated. If we update this Privacy Policy, in certain circumstances, we may seek your consent.</p>
              <br />
              <h2>12. Contact Us</h2>
              <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your information described herein, your choices and rights regarding such use, or wish to exercise your rights, please do not hesitate to contact us at <a href="mailto:hello@syncso.com">hello@syncso.com</a>.</p>
            </div>
          </Content>
        </ContentContainer>
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #FFFFFF;
  padding-bottom: 100px;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Rubik', sans-serif;
  line-height: 1.8;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
  color: #101828;
  font-weight: 700;
`;

const LastUpdated = styled.p`
  text-align: right;
  margin-bottom: 40px;
  color: #475467;
  font-style: italic;
`;

const Content = styled.div`
  h2 {
    color: #101828;
    font-size: 24px;
    margin: 30px 0 20px;
    font-weight: 600;
  }

  h3 {
    color: #344054;
    font-size: 20px;
    margin: 25px 0 15px;
    font-weight: 500;
  }

  h4 {
    color: #475467;
    font-size: 18px;
    margin: 20px 0 10px;
    font-weight: 500;
  }

  p {
    color: #475467;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.8;
  }

  ul {
    margin: 15px 0;
    padding-left: 20px;
    
    li {
      color: #475467;
      margin-bottom: 8px;
      font-size: 16px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    
    th, td {
      border: 1px solid #E4E7EC;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: #F9FAFB;
      color: #101828;
      font-weight: 600;
    }

    td {
      color: #475467;
    }
  }

  a {
    color: #175CD3;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default PrivacyPolicy;
